// variables

@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@200;400&display=swap);

$background-color: #211d32;
$background-color2: #262335;

$link-color: #e812ff;
$font-color: #eee;
$font-family: "Mulish", sans-serif;
$font-style-light: 200;
$font-style-regular: 400;
$font-size-regular: 1.2em;
$font-size-h1: 1.6em;
$font-size-h2: 1.2em;

$line-height: 1.2;

$spacing-small: 12px;
$spacing-middle: 18px;
$spacing-big: 24px;

$margin-bottom: 20vh;
$margin-top: 2vh;
