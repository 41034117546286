// artwork

.m-artwork {
    padding: 0 15px;
}

.m-thumbnails {
    display: grid;
    grid-gap: 15px 5%;
    grid-template-columns: repeat(3, 30%);
    padding-bottom: 15px;
    @media only screen and (min-width: 768px) {
        grid-gap: 15px 2%;
        grid-template-columns: repeat(5, 18.5%);
    }

    > button {
        cursor: pointer;
        max-width: 100%;
        overflow: hidden;

        &.show {
            display: flex;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }
}

.m-artwork-panel {
    background: $background-color2;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    list-style-type: none;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: fixed;
    top: 0;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0);
    width: 100vw;
    -webkit-font-smoothing: antialiased;
    z-index: 3;
}

.m-artwork-panel--open {
    transform: none;
}

.m-artwork-item {
    align-items: flex-start;
    box-sizing: border-box;
    height: 100%;;
    justify-content: flex-end;
    width: 100%;

    figcaption {
        margin-left: 50px;
        padding-bottom: 20px;
        @media only screen and (min-width: 768px) {
            margin: 0 auto;
        }
    }
}

.m-artwork-item-image {
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;
}

.m-artwork-item-image img {
    box-sizing: border-box;
    cursor: pointer;
    object-fit: contain;
}

.m-artwork-item-image--none {
    opacity:0;
}

.m-artwork-item-image--loaded {
    animation: fadeIn ease-in 300ms;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}