// filter

.a-filter {
    margin-bottom: 20px;
    position: relative;
    text-align: right;
    width: 100%;

    &::before {
        align-items: center;
        color: $link-color;
        content: "\2193";
        display: flex;
        font-size: 24px;
        height: 35px;
        position: absolute;
        right: -4px;
        z-index: -1;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        border: 0 none;
        border-top: 1px solid $link-color;
        border-bottom: 1px solid $link-color;
        color: $link-color;
        font-size: $font-size-regular;
        font-weight: $font-style-light;
        -moz-padding-start: calc(10px - 3px);
        padding: 5px 15px 5px 0;
        text-align-last: center;
    }

    select::-ms-expand {
        display: none;
    }

    select:focus,
    select:active, 
    select:hover {
        outline: none;
    }

    select:focus::-ms-value {
        background-color: transparent;
    }

    option {
        background-color: $background-color2;
    }

    option:hover,
    option:checked {
        background-color: $link-color;
        color: $background-color2;
        box-shadow: 0 0 10px 100px $link-color inset;
    }
}
