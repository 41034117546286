// general

body {
  background: $background-color;
  color: $font-color;
  font-family: $font-family;
  font-size: $font-size-regular;
  font-weight: $font-style-light;
  line-height: $line-height;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 600px;
  margin: 0 auto;
}

body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: $link-color $background-color2;
}
body::-webkit-scrollbar-track {
  background: $background-color2;
}
body::-webkit-scrollbar-thumb {
  background-color: $link-color;
  border-radius: 6px;
  border: 3px solid $background-color2;
}

section,
figure {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

h1 {
  font-size: $font-size-h1;
  font-weight: $font-style-light;
}

body > * {
    transition: all 300ms ease-in;
}

.fade-in {
    opacity: 0;
}
.fade-in.visible {
    transition: opacity 300Ms ease-in;
    opacity: 1;
}

img.title {
  max-width: 620px;
  margin-bottom: 6vh;
}

img {
  height: auto;
  max-height: calc(95vh - 125px); // for large heights of paintings
  width: 100%;
  max-width: 100%;
}

h2 {
  font-size: $font-size-h2;
  font-weight: $font-style-regular;
  margin: $spacing-small 0;
}

.a-image-logo {
    margin: 125px auto 50px auto;
    padding: 0 50px;
}

a,
.btn,
.m-menu-side__menu-button {
  background: 0 none;
  border: none;
  color: $link-color;
  cursor: pointer;
  font-family: $font-family;
  font-size: $font-size-regular;
  font-weight: $font-style-light;
  line-height: $line-height;
  outline: none;
  padding: 0;
  text-decoration: none;

  &.active,
  &.m-menu-side__menu-button--active {
    font-weight: $font-style-regular;
  }
}

.btn {
  display: block;
  text-align: left;
  width: 100%;
}

.a-link--available {
    font-size: 100%;
}

nav#language {
  align-items: flex-start;

  ul {
    width: 100%;
  }

  li {
    display: inline-block;
    min-width: 20%;
  }

  .btn {
    font-size: 1em;
  }
}


p + p {
    margin-top: 24px;
}

table {
  tr {
    text-align: left;
  }
  th {
    padding: 16px 0;
  }

  td + td {
    padding-left: 16px;
  }
}

.a-text--bio {
    table {
        td:first-of-type {
            min-width: 110px;
        }
    }
}

.show {
  display: block;
}

::-moz-selection,
::selection {
    background-color: $link-color;
    color: $background-color2;
}


