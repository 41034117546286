// menu

.m-menu-side__toggle {
    display: block;
    position: absolute;
    top: 50px;
    left: 50px;
    -webkit-user-select: none;
    user-select: none;
    z-index: 2;
}

.m-menu-side__toggle input {
    cursor: pointer;
    display: block;
    height: 32px;
    left: -5px;
    opacity: 0; /* hide this */
    position: absolute;
    top: -7px;
    width: 40px;
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
}

.m-menu-side__toggle span {
    background: $link-color;
    border-radius: 3px;
    display: block;
    height: 1px;
    margin-bottom: 5px;
    position: relative;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    width: 32px;
    z-index: 1;
}

.m-menu-side__hamburger-line-1 {
    transform-origin: 0% 0%;
}

.m-menu-side__hamburger-line-2 {
    margin-left: 1px;
    transform-origin: 0% 0%;
}

.m-menu-side__hamburger-line-3 {
    margin-left: -1px;
    transform-origin: 0% 100%;
}

.m-menu-side__toggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(0, -1px);
    background: $link-color;
}

.m-menu-side__toggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}


.m-menu-side__toggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(1px, 2px);
}

.m-menu-side__menu {
    background: $background-color2;
    height: 100vh;
    list-style-type: none;
    margin: -50px 0 0 -50px;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: 0;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0);
    width: 100vw;
    -webkit-font-smoothing: antialiased;
}

.m-menu-side__menu ul {
    margin: 100px 0 0 50px;

    & + ul {
        margin-top: 20px;
    }
}

.m-menu-side__menu li {
    padding: 10px 0;
    font-size: 22px;
}

.m-menu-side__toggle input:checked ~ .m-menu-side__menu {
    transform: none;
}

.m-menu-side__menu-button--home.active {
    font-weight: 200;
}
